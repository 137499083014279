import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import './index.css';
import App from './App';
import Nav from './components/Nav'
import Footer from './components/Footer'
import FestivalsVision from './components/FestivalsVision'
import FestivalsGuide from './components/FestivalsGuide'
import FestivalsTeam from './components/FestivalsTeam'
import ConferencesVision from './components/ConferencesVision'
import ConferencesFoundation from './components/ConferencesFoundation'
import ConferencesTeam from './components/ConferencesTeam'
import ConcertsSteps from './components/ConcertsSteps'
import ConcertsDay from './components/ConcertsDay'
import ArtistDevelopmentVision from './components/ArtistDevelopmentVision'
import ArtistDevelopmentEpk from './components/ArtistDevelopmentEpk'
import ArtistDevelopmentLive from './components/ArtistDevelopmentLive'
import FirstTourVocabulary from './components/FirstTourVocabulary'
import FirstTourMerch from './components/FirstTourMerch'
import SmartTouring from './components/SmartTouring'

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
