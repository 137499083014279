import React from 'react'
import '../index.css'
import pic1 from '../images/ArtistDevelopmentLive.jpg'

const ArtistDevelopmentLive = () => {
  return (
    <div className="concerts-content main">
      <h1>Solidifying your live performance</h1>
      <span className="span-for-side-pics">
      <ul>
      <li>STAGE PRESENCE EVALUATION</li>
      <li>OVERALL MUSICIANSHIP</li>

      <li>CROWD INVOLVEMENT (Do they participate? Are all band members engaging the crowd?
      How does the crowd respond to attempts to engage? Suggestions for change?)</li>

      <li>ON STAGE BAND COMMUNICATION (Nonverbal and verbal communication - is it there?
      Examples, How to show it more, general impression of cohesiveness in the group)</li>

      <li>BAND MOVEMENT ON STAGE (Organized chaos, non-existent, ways to make it better)</li>

      <li>FLOW OF SHOW (Are there unnecessary stops in the show? What can be done to cover them?
      Is there engagement with delays? Is there a story that carries the show? Learn about the band?
      Do they communicate their hearts with the set?)</li>

      <li>OVERALL APPEARANCE OF BAND (Branding, clothes, stage attitude, own the stage, run the
      set, looking at people, photo worthy, video worthy, compares to recorded music how?, etc)</li>
      </ul>
      <img className="general-images" src={pic1} />
      </span>
    </div>
  )
}

export default ArtistDevelopmentLive;
