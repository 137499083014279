import React from 'react'
import pic1 from '../images/ConferencesVision1.PNG'

const ConferencesVision = () => {
  return (
    <div className="conferences-content main">
      <h1>Vision</h1>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/LNBxKskPX9c" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <p>
        So you want to host a conference?  Easy peasy!  There are a few things you need to remember when starting this endeavor.
        </p>
        <span className="span-for-side-pics">
        <div>
        <p>
        First, know that this event will become a living breathing creature that will begin in one space and then mutate multiple times into something greater than you knew it could be.
        </p>
        <p>
        Second, you can’t do this alone.  You must build a team of people who can assist and support you in all endeavors.  This team needs to be talented in many areas,  widely varying in age range as well as male/female, and have the ability to be flexible to the point of ultimate limbo.
        </p>
        <p>
        Lastly, you will use every resource you have for information and if you are not careful, this festival can consume every part of who you are and what you do...find balance and set boundaries now.
        </p>
        </div>
        <img className="general-images" src={pic1} />
        </span>
        <p>
        Whew...now the scary stuff is out of the  way, so let’s move forward in this!  The very first step in any festival effort is to figure out what kind of conference you are planning to have.  This is called creating the vision of the conference.  It generally starts with an idea and then it brainstorms its way into an all out event.  Part of the vision is also the purpose.  Choosing a focus is not always the easiest thing to do but think about the big picture and keep it simple.
        </p>
        <p>
        After you have an idea of what the conference looks like, brainstorm some fun activities and features you would like to include in the event. This will help to bring more fruition to the end picture (or vision).  Again, this is a brainstorming session, so zero restrictions apply.  Get creative, team!
        </p>
        <p>
        The next step of finding a vision is to look at the big picture of what your brainstorming has created...areas of interest, repeating issues, primary focus, repeating statements, and the target audience.  Look at the common themes that come up in discussion to begin forming the conference theme.
        </p>
        <p>
        The last step is to take this information and define it in one or two easy to read and remember sentences.  This will become your vision statement by which all major decisions will be made.  Include why you are doing this (purpose), who this is for (target audience), and how you intend to fulfill the purpose.  This will become the team motto and the foundation on which the entire event will be built upon, so be sure to leave room for growth and evolution as the years to come will allow for modernizations to come to pass.
        </p>
        <p>
        The vision is the buy in for all team members and sponsors.  They must agree with and support the vision as though it were their own as without the buy in, the team will stray or lose interest.
        </p>
        <p>
        Now that you have an idea of what you want to do and why, let’s build a team!
        </p>
    </div>
  )
}

export default ConferencesVision;
