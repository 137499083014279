import React from 'react';
import logo from '../logo.svg';
import '../App.css';
import melindaHomePage from '../images/melindanew.jpg'
import logoRainbow from '../images/LogoRainbow.png'

function Home() {
  return (
    <div className="App main">
      <section>
        <img src={logoRainbow} className="logo"></img>
        <h1>About TOP Entertainment</h1>
        <p>That One Place Entertainment is connecting people and industries through live interactive events.  </p>

        <p>What does that mean and how does that happen?  Live interaction is a great way to connect people.  A face, a handshake, the exchange of business cards and other information is the beginning of growing relationships and friendships with like minded people.  It is also a way to “sharpen your iron” and expand your perspectives as people with different mindsets and new knowledge are encountered to challenge what you believe.  Expanding our minds and creativity is the heart of the culture of today and that can be
        done in many ways.  Live events and interaction is the most effective long-term way to do this.  Here at TOPE, we strive to make your event and your role in it the best we can. </p>

        <p>Many events include live music as either a focus or a part of the event itself.  As a result, we work with bands and artists to teach them to present themselves in the most impactful way possible without dulling down the creativity of the musical art they create.  More, we work to enhance what they do through teaching the business side as well as working with them on their live performances.  If you are an artist or musician, please contact us for more information and to find a way we can assist you.
        </p>

        <iframe width="560" height="315" src="https://www.youtube.com/embed/noj8Jm1p1hU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </section>
      <section>
      <h1>About Melinda</h1>
        <p>
        Melinda Ebert is a team builder, big picture visionary, event organizer, and bridge.  Having organized a large number of festivals and events around the United States over the past 9 years, she believes that when people find confidence and encouragement in their true gifts, they can  move forward in them.  The perfect career and inspiration for life can be found and fostered into something amazing! Melinda specializes in planning music related events that unite community groups that may not normally find each other or work together creating a bond in the community that didn’t exist before.  Uniting industries through live interactive events fosters people working together, getting past the handshake relationship and growing deeper into a working relationship showcasing the talents of all parties involved.  Melinda’s undying enthusiasm will encourage you!  Contact her today.
        </p>
        <img src={melindaHomePage} className="introPic"/>
      </section>
    </div>
  );
}

export default Home;
