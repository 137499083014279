import React from 'react'
import '../App.css';
import pic1 from '../images/YourFirstTourMerch.jpg'

const FirstTourMerch = () => {
  return (
    <div className="concerts-content main">
    <h1>MERCHANDISE DISPLAY</h1>
    <p>DISPLAY</p>
    <ul>
    <li>Multiple levels to look at</li>
    <li>Table is clean in the center leaving space for signing, conversation, and such</li>
    <li>Only samples are on the table, take new from the box (or appearance of abundance). Most
    people don't want something people have been handling</li>
    <li>Table cloth</li>
    <li>Something to do while waiting in line</li>
    <li>Prices clearly marked</li>
    <li>Signs and info neatly displayed (in frames, all in one location - white board or other means)</li>
    <li>Creative ways to bring attention to your table</li>
    <li>Banner to clearly show whose table you are at (or other form of logo/brand recognition)</li>
    <li>Shirts clearly displayed</li>
    </ul>
    <p>MERCHANDISE</p>
    <span className="span-for-side-pics">
    <ul>
    <li>Variety of price ranges (free to high dollar)</li>
    <li>Include merchandise for that seeker of the unique and original - tennis shoes, drumhead art,
    destroyed guitars, broken sticks, sweaty shirts, microphones that died, show pants (clothes),
    bloody anything</li>
    <li>Packages available (bags/containers that advertise free with purchase)</li>
    <li>Make it special for the buyer</li>
    <li>Variety of items that speak to people in your target group - what do they consume in daily life
    that you can convert into your brand with logo for marketing?</li>
    <li>Music to purchase and take with them (cd, download card, flash drive, etc)</li>
    <li>Social media links to go (put on card and be sure they go out with every purchase as well as
    for free...add a value to it so people keep it for later.)</li>
    <li>Add something for competition amongst fans (cups, condoms, coffee, etc). Something
    inexpensive so they can purchase one or all as a set. Winner announced at the end of the
    run....who will it be and why? :-). Encourage participation</li>
    <li>VIP fun. What can you contribute to the VIP experience? Something to make it special</li>
    <li>Non-profit charity draws people to the table...what do you support? How can you benefit both
    the non-profit and your merchandise area?</li>
    <li>Drawing for dinner with the band? Or VIP tickets for dinner with the band? Lots of
    inexpensive options for a full experience no one else will get. Make VIP more that a glorified
    autograph and photo session. Make it personal and something to hang on to that no one else
    will experience.</li>
    </ul>
    <img className="general-images" src={pic1} />
    </span>
    <p>MERCH SALES PROCESS. (Everyone has their own system. If it works, use it. Just be sure it
    is there.)</p>
    <ul>
    <li>System of taking inventory</li>
    <li>System to track sales</li>
    <li>System to track money from merch</li>
    <li>System to track money paid out for merch sales as well as other expenses</li>
    <li>System for easy access during the sales time keeping the station tidy and organized</li>
    </ul>
    </div>
  )
}

export default FirstTourMerch;
