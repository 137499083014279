import React from 'react'
import pic1 from '../images/FestivalTeam1.JPG'
import pic2 from '../images/FestivalTeam2.JPG'

const FestivalsTeam = () => {
  return (
    <div className="festivals-content main">
      <h1>Team</h1>
      <span className="span-for-side-pics">
        <div>
          <p>Building a team for a festival is a great yet difficult challenge.  Your team needs to be multi talented and have resources, along with logic, to access for each part of a festival needs.  It is ok if you and your team still need to learn some aspects of how a festival operates, but having experience in the components will be important.  All of your team members will need to work together for the greater whole.  Some spaces need more work and attention than others at different points in the mutation, so be sure your group is made up of all people willing to help each other out.  Also, be sure you have a mixture of sexes and ages represented on your team as the insight of each group will be most helpful to you as you progress with this.   Some of the topics you will want to consider when building your team:
          </p>
        </div>
        <img className="general-images" src={pic1}/>
      </span>
      <span className="span-for-side-pics">
        <img className="general-images" src={pic2} style={{ padding: "1rem"}}/>
        <h2>YOU: Founder, CEO, Head Cat Herder (so you can give this position to another person you trust with the vision if you have a specialty in another space, but you must let that person do their job!)
        You need to be in the know of what is happening in each area at all times.  You are also responsible to ensure spending and funds are all working within the determined budget (you can write this or have help writing this), you also have the honor of ending disagreements and basically running a small community of people all working toward the same end result.  You are the face and the personality of the event.  Live it. Breathe it. Be it.  This is important when building bridges for the festival and sponsors. You are everything including the team cheerleader and encourager, tough decision maker and head communicator.
        </h2>
      </span>

      <h2>Security</h2>
      <p>Gate security to ensure safety of patrons, stationed security, stage and artist security, roving security, if needed - overnight security, parking lot security, beer garden security and ID’s, campground security, other spaces that need special attention.  Includes both identified and undercover, higher level security.
      </p>

      <h2>Logistics & Stages</h2>
      <p>Traffic flow, restrooms and cleaning schedules, trash locations and pick up, power, water access, handwashing stations, quiet spaces vs not quiet spaces, fencing, registration/ticket locations, communication systems, contact with venue, city ordinances and rules, noise and parking restrictions, drop off points for lyft/uber, parking lots, camping location (if applicable), stage and vendor locations so sound isn’t overlapping and bring the food to the people vs bring the people to the food……..this is a long list….let’s just say miscellaneous human needs. Ha!</p>

      <h2>Technological skills</h2>
      <p>Ticketing, website, social media, live streaming, live events recording and streaming,
      stage gear (audio visual ) and how they work with the rest of the event, wifi/internet access, power</p>

      <h2>Staging</h2>
      <p>Stage, equipment, microphones, monitors, soundboard, lights, risers, stage plots, recording, wifi, di boxes, xl cables, set up, tear down, rain gear, more...stage manager and crew (volunteer peeps or paid) to switch between sets
      </p>

      <h2>Vendors</h2>
      <p>Food and beverage, artist merchandise sales, other related sales, power, logistics for the space, water access
      </p>

      <h2>Grounds keepers</h2>
      <p>Clean up crew, trash duty, monitor dumpsters to know when they need emptied</p>

      <h2>Artist Booking and Hospitality</h2>
      <p>Booking all musicians and emcees for the stages involved in your festival,  hospitality for stage members (performers and crew), manages parking lot control and logistics for stage space, manages stage manager for each stage, manages crew for each stage</p>

      <h2>Marketing and Publicity</h2>
      <p>Manages festival website content, social media content, live marketing opportunities, poster distribution and hanging, other marketing and publicity opportunities. Graphic design is a part of this group for consistency purposes</p>

      <h2>Volunteer/Paid Help</h2>
      <p>Manage and recruit all volunteers in all areas of the festival</p>

      <h2>Clerical/Accounting</h2>
      <p>Manages all monies and the budget updates, manages ticketing and registration, writes the checks (2 signatures) as needed for the entire festival, communicates clearly with each team, communicates with sponsors as needed</p>

      <h2>Sponsorship Coordinator</h2>
      <p>Builds relationships for funding for all festival needs.  Works closely with Head, Booking, marketing, and logistics teams to ensure proper displays and materials are properly distributed and sponsors are well recognized.  This person works with all teams.
      </p>

      <p>Once you have a solid team in place, share the vision and make it clear that this is the foundation on which the festival will be built.  After that, you will need the legal work to show buy in for each team member and to determine how to replace team members who aren’t actively working with you or are not cooperative (kick them out..ha!).  It is important to have this in place in case you need it rather than hoping you don’t need it.  You will also need a confidentiality agreement and any other documents you deem necessary.</p>

      <p>After all of this is done, treat your team well...they like to be fed at meetings and some drinks don’t hurt either!  Recognize a job well done and offer help when someone falls behind or doesn’t understand.  Keep compiling resources and relationships as you grow into what the festival you created.  When the fest is over, rest for a couple of weeks, and then start again for next year :-)  You will have thoughts and ideas in place to start on anyhow.  Evaluate the previous year and then move forward continuing to grow and mutate the event to the next level each year.  Stay safe and stay up to date.  HAVE FUN!
      </p>
    </div>
  )
}

export default FestivalsTeam;
