import React from 'react'
import '../index.css'

const NoMoreSadSongs = () => {
  return (
    <div className="concerts-content main">
      <h1>#NoMoreSadSongs</h1>
      <span className="span-for-side-pics">
      <p>
        <strong>Hello!</strong>
        <p>Welcome to the #NoMoreSadSongs campaign.  It has come to my attention and observation that many songwriters, especially located in the Nashville area, have been on a binge of writing and singing sad songs for the crowds at their performances.  Please STOP!  WE CAN’T HANDLE IT ANY LONGER!!   You are still loved and still great, but please, express different emotions in the songs you choose. </p>
        <p>As a result of too many sad songs, the following side effects have been observed:</p>
        <ul>
          <li>People in the audience are brought down from the fun they were having prior to arriving at said establishments.</li>
          <li>Audiences are dwindling at songwriter rounds.</li>
          <li>Audiences attending songwriter rounds tend to treat the performers as background noise over a purpose for being at the bar/restaurant where they are performing.</li>
          <li>People are buying less product and staying for shorter time periods, including performers!  This makes establishments frustrated as they are working to increase sales. </li>
          <li>Sad songs make the atmosphere sad.  Yes, they provoke emotion, but do they really fit the mood your host is looking to create?  Are people smiling, laughing, and enjoying themselves?  Many come to escape the sadness and now they are just bombarded with it. </li>
          <li>Sad songs can still happen but need to be presented in a happier and more fun loving way with a lively BPM in order to keep the crowd moving and happy.</li>
        </ul>
        <p>Please play sad songs with caution and extreme rarity.  They make great transitions in longer sets, but a songwriter round is not a set.  It is little snippets you get to share with your audience.  A set takes the audience on a journey with the artists and thus can’t be done in a songwriter round as you are mixing multiple adventures into one grouping.  A multitude of murder death kill songs make the audience want to do the same.  PLEASE STOP KILLING YOUR FANS!!</p>
        <p>If you would like help with your sad songs and presentation, contact me.  If you are interested in making sad songs positive, contact me.  If you play fun and happy songs, I have a place for you, contact me!</p>
        <p>Ok, rant over.  Carry on with <strong>#nomoresadsongs</strong>.</p>
        <p>
          Melinda Ebert
          <br/>
          That One Place Entertainment
        </p>
      </p>
      </span>
    </div>
  )
}

export default NoMoreSadSongs
