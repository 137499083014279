import React from 'react'
import { BrowserRouter as Link, NavLink, Router } from 'react-router-dom'
import icon1 from '../images/facebook.png'
import icon2 from '../images/instagram.png'
import icon3 from '../images/mediumlogo.png'
import icon4 from '../images/pamperedchef.png'
import icon5 from '../images/linkedinlogo.png'
import icon6 from '../images/youtube.png'

const Footer = () => {
  return (
    <div className="footer">
      <section className="footer-content">
        <h2>Let’s Build It Together!</h2>

        <p>Now that you have a small taste of what it takes for success, are you ready?  Then, let’s go!!!!!!</p>

        <p>Not sure where to start?  I can help you with that!  Contact me by emailing me at <a className="mobile-link" href="mailto:topentrocks@gmail.com">topentrocks@gmail.com</a> and we can discuss tackling this dream together!</p>

        <p>We offer both implementation services as well as consulting services.  Either way, our goal is to help you have the best event you can create and to be able to do it independently after the first few years or when you are comfortable with taking over as your team is ready.  Providing stages for our performers and crew to work with as well as other opportunities is a privilege we don’t take lightly.  We love our side and we love our guests who come to check it out!  Let That One Place Entertainment be your source for all of your event needs!
        </p>
        <span className="icon-container">
          <a href="https://www.facebook.com/artistresource/?eid=ARBwG0gvGPABgLRQCOCUDRbDyg2sqorzZubqhcRgP7j79TS-oJTy8WydLyL-RLfPskAoV_l3A5cC_hJQ&timeline_context_item_type=intro_card_work&timeline_context_item_source=742331899&fref=tag"><img className="icon" src={icon1}/></a>
          <a href="https://www.instagram.com/topent/"><img className="icon" src={icon2}/></a>
          <a href="https://medium.com/@thatoneplaceentertainment/"><img className="icon" src={icon3}/></a>
          <a href="https://linkedin.com/in/melindaebert"><img className="icon" src={icon5}/></a>
          <a href="https://www.youtube.com/channel/UCxoiJIMh54HXgP-gUowc3zQ"><img className="icon" src={icon6}/></a>
          <a href="https://www.pamperedchef.com/pws/mebert?fbclid=IwAR3z6sgZ0T52HUG2h_MTH2mp_vdNYRRahZ_k98mB6KvX3GAiKIvGsIFatP8"><img className="icon" src={icon4}/></a>
        </span>
      </section>
    </div>
  )
}

export default Footer;
