import React from 'react'
import "../index.css"

const SmartTouring = () => {
  return (
    <div className="concerts-content main">
      <h1>Touring Smart</h1>
      <p>Assuming you have a solid foundation, you are now ready to tour!  It is what so many bands dream of...touring around sharing their art with the world!!  What is stopping you?  Let’s find out and then, get you out on the road!  Being a road warrior is a huge deal, but it isn’t an easy lifestyle.  Before you set out on this journey, you need to consider your family, your current lifestyle, and your income.  Where are your priorities and is being on the road for long periods of time right for where you are right now?  Here are some things to consider when preparing for your first time out...please start with a short trip no longer than a week or two.</p>
      <ul>
      <li>Do you have all of the instruments, cabs, amps, cables, microphones and other equipment you need for a successful show?</li>
      <li>Have you blocked out and rehearsed your sets to know who is doing what for each song in your set? Have you worked through how to reach your audience in every song?</li>
      <li>Do you have reliable transportation that can be your home for long periods of time?  Did you get the oil changed, have tires, breaks, etc checked to ensure safety on the road?</li>
      <li>Do you have a social media presence?  Take time to develop social media and website pages so you have a place to refer people to for more information.  Be sure to make it personal and include general info about the band members as fans are looking for a more personal connection.  At the same time, beware that fans don’t forget anything, so be at the top of your game always on social media.  Keep it positive and true.  One slip and people will know.</li>
      <li>Do you have a press pack ready to share with potential venues and touring bands?  A press pack should be email ready and include: band photo, music samples, bio, and any other information the person receiving the message should know about you.  This is the first and often only exposure some people will receive of what you have to present.  Be sure the wording and presentation truly represent who and what your band is.  Also note what is unique or special about your band that makes it stand out among the others that may be similar to you…why should you be chosen?</li>
      <li>Do you have a marketing plan?  Do you know who your biggest audience is?  How do you market to them?  What can you do to draw the attention of the people that listen to you most?</li>
      <li>Do you have a way for fans to take a part of you with them after they experience the music you share with them? It is best to have an EP or album as well as merchandise printed.  People love the experience of live music.  Those who buy at the shows relive that experience every time they see that merchandise or hear the songs you played that show.  Give them an excuse to relive it repeatedly.</li>
      <li>Do you have a contract with a rider written up?  This is not mandatory, but is helpful when communicating with the venue as well as when money issues arise.  You don’t have to use it every time, but it does make communication easier.  These can be changed and adapted as needed also. A good contract should have the first page with the basic info for the show and the agreement with both parties on it.  The next pages should be the stage plot and then what the band requests of the venue for safety and necessities with some optional amenities such as food, water, showers, laundry, etc.</li>
      <li>Do you have shows to play?  Book shows wherever you can to start with and grow into a place where you can be more selective based on where your friends are.  Remember your fans are your friends.  Many know more about you than your parents do</li>
      <li>Are the shows you have booked advertised well?  Social media, posters locally and beyond, handbills distributed, etc…. what about the venues…do the usual crowds support the style of music you play? If not, what is special about your band that can be tapped into to draw people?</li>
      <li>Do you have a plan to communicate with the people you are leaving at home while on this journey?  It is important to have a way to stay in touch and talk about the mundane daily things so that when you return home, you aren’t strangers.  If you have children, find a way to play with them through video chat or other means.  They will appreciate it greatly.  Staying connected is best for everyone’s mental health.</li>
      <li>Do you have something you can do while stuck in a vehicle for hours at a time?  Mental awareness is good to have.  Be aware that you won’t always have cell service or internet, so make sure there are options that don’t include these such as puzzle books, reading books, downloaded podcasts, and so on.  You will be  exhausted for a lot of the tour as your time will mostly be spend with band stuff, but it is nice to have an escape when you have down time.  Also, know your personality type.  Know if you need alone time or talking time and how to deal with lacking the one you are not able to do given the circumstances.  For the sanity of all, this is important.</li>
      </ul>
      <p>There are a ton of factors and little things you can do to make your tour go smoothly and well.  These are just a few of them.  For more details, email me at <a className="mobile-link" href="mailto:thatoneplaceentertainment@gmail.com">thatoneplaceentertainment@gmail.com</a>!
      </p>
    </div>
  )
}

export default SmartTouring;
