import React from 'react';
import '../App.css';
import pic1 from '../images/ArtistDevelopmentVision1.JPG'

const ArtistDevelopmentVision = () => {
  return (
    <div className="concerts-content main">
      <h1>Vision</h1>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/CKhHDdo-bgA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <span className="span-for-side-pics">
      <div>
      <p>You want to start a band? YAHOOOOOOOO!!!!!! I’m so happy for you!  This is a trip you will never forget no matter the end result.  You will learn more about yourself than ever before!  You got this!  But….where do you start?  Let’s look at the beginning.  These are all questions you will need to consider as you get started.</p>
      <p>You need a vision for why the band exists.  Why do you want to start a band?  What primary sound are you looking for?  What is the best way to achieve that sound live and recorded?</p>
      <p>Which instruments do you need live and what is ok to track (what is a track?)?  Do the people selected to be in the band understand the purpose and sound you are looking for?  Do your personalities mix well?  Do you pull from a variety of backgrounds to create your own uniqueness?  Are the members committed to the band - is there buy in?</p>
      <p>Practice Space is important.  Do you have a private place you can practice and create your art that is safe and accepting of what you are working to accomplish?  Are your band members ready to be a part of a team or is a dictatorship really more what you see happening (hopefully the team concept wins!)</p>
      <p>Are you ready to create originals or are you a cover band?  What defines you as a group?</p>
      </div>
      <img className="general-images" src={pic1} />
      </span>
      <p>Once you have these questions answered, you are ready to move onto the next steps preparing to perform live and eventually, to go on tour.  These are some of the items you will need to show you are serious about your art.  It can be expensive and takes time to develop and implement a solid plan.</p>
      <ul>
        <li>Create a Business Plan</li>
        <li>Solidify Your Live Performance as best you can (choreography, clothes, genre, lights, sound, overall appearance, and more...what’s the big picture; the on stage dream?)</li>
        <li>Create your social media sites and your website using material from local shows</li>
        <li>Build your local fanbase</li>
        <li>Build your online presence</li>
        <li>Get professional photos taken</li>
        <li>Create An Electronic Press Kit (EPK)</li>
        <li>Solidify your band and team </li>
        <li>Record a demo album or a full length...your fans want some way to relive their great memories</li>
        <li>Assign roles to various team members based on what they enjoy and their personality types</li>
        <li>Acquire a vehicle to get to and from shows in as a group.  Be sure you have space for equipment.</li>
        <li>Acquire merchandise to sell at shows and to generate more income to cover your expenses as a band</li>
        <li>Research and explore all of the ways you can earn income from your music and platforms</li>
      </ul>
      <p>This is a solid base to get going.  For more details, use the contact form on the HOME page!</p>
    </div>
  )
}

export default ArtistDevelopmentVision;
