import React from 'react'
import pic1 from '../images/ConferencesTeam1.JPG'

const ConferencesTeam = () => {
  return (
    <div className="conferences-content main">
      <h1>Team</h1>
        <div>
          <p>
          Building a team for a conference is a great yet difficult challenge.  Your team needs to be multi talented and have resources, along with logic, to access for each part of the conference needs.  It is ok if you and your team still need to learn some aspects of how a conference operates, but having experience in the components will be important.  All of your team members will need to work together for the greater whole.  Some spaces need more work and attention than others at different points in the mutation, so be sure your group is made up of all people willing to help each other out.  Also, be sure you have a mixture of sexes and ages represented on your team as the insight of each group will be most helpful to you as you progress with this.   Here are some of the topics you will want to consider when building your team:
          </p>
          <span className="span-for-side-pics">
          <div>
          <h2>
          YOU: Founder, CEO, Head Cat Herder (so you can give this position to another person you trust with the vision if you have a specialty in another space, but you must let that person do their job!)
          </h2>
          <p>
          You need to be in the know of what is happening in each area at all times.  You are also responsible to ensure spending and funds are all working within the determined budget (you can write this or have help writing this), you also have the honor of ending disagreements and basically running a small community of people all working toward the same end result.  You are the face and the personality of the event.  Live it. Breathe it. Be it.  This is important when building bridges for the festival and sponsors. You are everything including the team cheerleader and encourager, tough decision maker and head communicator. Your name will also be on all of the contracts with vendors.</p>
          </div>
          <img className="general-images" src={pic1} />
          </span>
          <h2>
          Security
          </h2>
          <p>
          What type of security is offered at the venue and what do you need to provide?  What is the price difference?  Where are specific spaces you need security to keep people safe and to ensure only your conference members are present in the space you are using?  Do you need parking lot security or escorts to parking spaces for safety?
          </p>
          <h2>
          Logistics & Stages
          </h2>
          <p>
          Traffic flow, restrooms and cleaning schedules, trash locations and pick up, power, water access, handwashing stations, quiet spaces vs not quiet spaces, registration/check in locations, communication systems, contact with venue, city ordinances and rules, noise and parking restrictions, drop off points for lyft/uber, parking lots, basically you are in charge of making sure basic human needs are met.  This will include working with the caterers and the venue to ensure a positive experience for all.  This person will also be working a schedule of activities - what is happening where, when and with whom?
          </p>
          <h2>
          Technological skills
          </h2>
          <p>
          Ticketing, website, social media, live streaming, live events recording and streaming,
          stage gear (audio visual ) and how they work with the rest of the event, wifi/internet access, power.
          </p>
          <h2>
          Staging
          </h2>
          <p>
          Stage, equipment, microphones, monitors, soundboard, lights, risers, stage plots, recording, wifi, di boxes, xlr cables, set up, tear down, rain gear, more...stage manager and crew (volunteer peeps or paid) to switch between sets - you need to have a manager for each space of the festival including the after parties.
          </p>
          <h2>
          Vendors
          </h2>
          <p>
          Food and beverage, vendors, other related sales and locations.  Works with logistics for power, water access (if needed), and any other special needs vendors may have.
          </p>
          <h2>
          Clean Up Crew
          </h2>
          <p>
          Clean up crew works with venue to determine who needs to do restroom clean up,  trash duty, monitor dumpsters to know when they need emptied.
          </p>
          <h2>
          Speaker/Artist Booking and Hospitality
          </h2>
          <p>
          Booking all people for the spaces involved in your conference, work with logistics for scheduling, hospitality for stage members (performers and crew), manages back stage and logistics for each space, manages stage manager for each space, manages crew for each space, creates a safe space for speakers and management to relax and prepare for their time with your audience as well as a space for volunteers and crew, organizes catering and other activities for these spaces.
          </p>
          <h2>
          Marketing and Publicity
          </h2>
          <p>
          Manages conference website content, social media content, live marketing opportunities, poster distribution and hanging, other marketing and publicity opportunities. Graphic design is a part of this group for consistency purposes.
          </p>
          <h2>
          Volunteer/Paid Help
          </h2>
          <p>
          Manage and recruit all volunteers in all areas of the conference.
          </p>
          <h2>
          Clerical/Accounting
          </h2>
          <p>
          Manages all monies and the budget updates, manages registration, writes the checks (2 signatures) as needed for the entire conference, communicates clearly with each team, communicates with sponsors as needed.
          </p>
          <h2>
          Sponsorship Coordinator
          </h2>
          <p>
          Builds relationships for funding for all conference needs.  Works closely with Head, Booking, marketing, and logistics teams to ensure proper displays and materials are properly distributed and sponsors are well recognized.  This person works with all teams.
          </p>
          <p>
          Once you have a solid team in place, share the vision and make it clear that this is the foundation on which the conference will be built.  After that, you will need the legal work to show buy in for each team member and to determine how to replace team members who aren’t actively working with you or are not cooperative (kick them out..ha!).  It is important to have this in place in case you need it rather than hoping you don’t need it.  You will also need a confidentiality agreement and any other documents you deem necessary.
          </p>
          <p>
          After all of this is done, treat your team well...they like to be fed at meetings and some drinks don’t hurt either!  This is your Dream Team!  Recognize a job well done and offer help when someone falls behind or doesn’t understand.  Keep compiling resources and relationships as you grow into what the conference created.  When the conference is over, rest for a couple of weeks, and then start again for next year. :-)  You will have thoughts and ideas in place to start on anyhow.  Evaluate the previous year and then move forward continuing to grow and mutate the event to the next level each year.  Stay safe and stay up to date.  HAVE FUN!
          </p>
        </div>

    </div>
  )
}

export default ConferencesTeam;
