import React from 'react'
import '../index.css'
import pic1 from '../images/ConcertsDay.jpg'

const ConcertsDay = () => {
  return (
    <div className="concerts-content main">
      <h1>What You Need To Know Day Of</h1>
      <span className="span-for-side-pics">
      <p>
        <strong>Day of the Show:</strong>
        <ul>
          <li>Be early</li>
          <li>Ensure all bands show up to soundcheck on time and answer any questions they may have</li>
          <li>Have hospitality (food and beverages) available and ready or a plan for it when the bands arrive for soundcheck</li>
          <li>Post the set times and order in a highly visible area backstage as well as giving one to the AV person/people.</li>
          <li>Greet fans when doors open and be ready for emergencies.</li>
          <li>When the first band hits the stage, relax and enjoy the show.</li>
        </ul>
        Note:  When you are hosting the show, it would be wise to keep your senses about yourself in case you have to put out fires or make adaptations to the main plan.  You want to be at the top of your game for this!  Limit alcohol and drug use to after the show.  You will be glad you did when an emergency occurs.

      </p>
      <img className="general-images" src={pic1} />
      </span>
    </div>
  )
}

export default ConcertsDay
