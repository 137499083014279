import React from 'react'
import pic1 from '../images/FestivalVision1.jpg'
import pic2 from '../images/FestivalVision2.JPG'

const FestivalsVision = () => {
  return (
    <div className="festivals-content main">
      <h1>Vision</h1>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/TynsG-pHjhY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <span className="span-for-side-pics">
        <div>
          <p>So you want to host a festival?  Easy peasy!  There are a few things you need to remember when starting this endeavor.
          </p>
          <p>First, know that this event will become a living breathing creature that will begin in one space and then mutate multiple times into something greater than you knew it could be.
          </p>
          <p>Second, you can’t do this alone.  You must build a team of people who can assist and support you in all endeavors.  This team needs to be talented in many areas,  widely varying in age range as well as male/female, and have the ability to be flexible to the point of ultimate limbo.
          </p>
          <p>Lastly, you will use every resource you have for information and if you are not careful, this festival can consume every part of who you are and what you do...find balance and set boundaries now.
          </p>
        </div>
        <img className="general-images" src={pic1}/>
      </span>
      <span className="span-for-side-pics">
        <img className="general-images" src={pic2}/>
        <div>
          <p>Whew...now the scary stuff is out of the  way, so let’s move forward in this!  The very first step in any festival effort is to figure out what kind of festival you are planning to have.  This is called creating the vision of the festival.  It generally starts with an idea and then it brain storms its way into an all out event.  Part of the vision is also the purpose.  Sometimes this is clear at the beginning and sometimes the purpose evolves through the vision process.
          </p>
          <p>After you have an idea of what the festival looks like, brainstorm some fun activities and features you would like to include in the festival. This will help to bring more fruition to the vision.  Again, this is a brainstorming session, so zero restrictions apply.  Get creative team!
          </p>
          <p>The next step of finding a vision is to look at the big picture of what your brainstorming has created...areas of interest, primary focus, repeating statements, and the target audience.  What can you pull from this information that will define what the festival really will be?
          </p>
        </div>
      </span>
      <p>The last step is to take this information and define it in one or two easy to read and remember sentences.  This will become your vision statement by which all major decisions will be made.  Include why you are doing this (purpose), who this is for (target audience), and how you intend to fulfill the purpose.  This will become the team motto and the foundation on which the entire event will be built upon, so be sure to leave room for growth and evolution as the years to come will allow for modernizations to come to pass.
      </p>
      <p>The vision is the buy in for all team members and sponsors.  They must agree with and support the vision as though it were their own as without the buy in, the team will stray and sponsors will find other uses for their funding.  It’s all related.
      </p>
      <p>Now that you have an idea of what you want to do and why, let’s build a team!
      </p>
    </div>
  )
}

export default FestivalsVision;
