import React from 'react'
import { BrowserRouter as Link, NavLink, Router } from 'react-router-dom'
import '../App.css'
import logoWhiteBG from '../images/LogoWhiteBG.png'
import noMoreSad from '../images/nomoresadsongs.png'
import HamburgerMenu from 'react-hamburger-menu';

class Nav extends React.Component {
  state = {
    festivals: false,
    conferences: false,
    concerts: false,
    artistDevelopment: false,
    yourFirstTour: false,
    open: false,
    hideOrShowHambugerDropDown: 'hamburgerDropDown'
  }

  handleClick = () => {
    this.setState({open: !this.state.open});
  }

  displayHamburgerMenu = () => {
        return (
            <HamburgerMenu
                    isOpen={this.state.open}
                    menuClicked={this.handleClick.bind(this)}
                    width={22}
                    height={18}
                    strokeWidth={2}
                    rotate={0}
                    color='#999999'
                    borderRadius={0}
                    animationDuration={0.5}
                />
        )
  }

  mobileNav = () => {
    return (
      <nav className='hamburgerDropDown nav-mobile'>
        <div className="nav-elem-home">
          <NavLink className="moble-nav-elem nav-elem" to="/" onClick={this.navReset}><img className="navLogo" src={logoWhiteBG}/></NavLink>
        </div>
        <div className="mobile-nav-elem nav-elem" onClick={this.handleFestivals}>FESTIVALS</div>
        <div className="mobile-nav-elem nav-elem" onClick={this.handleConferences}>CONFERENCES</div>
        <div className="mobile-nav-elem nav-elem" onClick={this.handleConcerts}>CONCERTS</div>
        <div className="mobile-nav-elem nav-elem" onClick={this.handleArtistDevelopment}>ARTIST DEVELOPMENT</div>
        <div className="mobile-nav-elem nav-elem" onClick={this.handleYourFirstTour}>YOUR FIRST TOUR</div>
          <NavLink className="nomoresad" to="/nomoresadsongs" onClick={this.navReset}><img className="nomoresadimg" src={noMoreSad}/></NavLink> 
      </nav>
    )
  }

  handleFestivals = () => {
    this.setState({festivals: !this.state.festivals,
    conferences: false,
    concerts: false,
    artistDevelopment: false,
    yourFirstTour: false})
  }

  handleConferences = () => {
    this.setState({conferences: !this.state.conferences,
    festivals: false,
    concerts: false,
    artistDevelopment: false,
    yourFirstTour: false})
  }

  handleConcerts = () => {
    this.setState({concerts: !this.state.concerts,
    conferences: false,
    festivals: false,
    artistDevelopment: false,
    yourFirstTour: false})
  }

  handleArtistDevelopment = () => {
    this.setState({artistDevelopment: !this.state.artistDevelopment,
    conferences: false,
    festivals: false,
    concerts: false,
    yourFirstTour: false})
  }

  handleYourFirstTour = () => {
    this.setState({yourFirstTour: !this.state.yourFirstTour,
    conferences: false,
    festivals: false,
    concerts: false,
    artistDevelopment: false})
  }


  navReset = () => {
    this.setState({festivals: false, conferences: false, concerts: false, artistDevelopment: false,
    yourFirstTour: false, open: false})
  }

  render(){
    return (
      <div className="nav-container">
        <div className="ham-container">
        { this.state.open ?  this.mobileNav() : this.displayHamburgerMenu()}
        </div>
        <nav className="navigation">
          <div className="nav-elem-home">
            <NavLink className="nav-elem" to="/" onClick={this.navReset}><img className="navLogo" src={logoWhiteBG}/></NavLink>
          </div>
          <div className="nav-elem" onClick={this.handleFestivals}>FESTIVALS</div>
          <div className="nav-elem" onClick={this.handleConferences}>CONFERENCES</div>
          <div className="nav-elem" onClick={this.handleConcerts}>CONCERTS</div>
          <div className="nav-elem" onClick={this.handleArtistDevelopment}>ARTIST DEVELOPMENT</div>
          <div className="nav-elem" onClick={this.handleYourFirstTour}>YOUR FIRST TOUR</div>
          <NavLink className="nomoresad" to="/nomoresadsongs" onClick={this.navReset}><img className="nomoresadimg" src={noMoreSad}/></NavLink>
        </nav>
        <section className="sub-nav-festival">
          {this.state.festivals ? <div className="sub-nav">
          <NavLink className="nav-sub-elem" to="/festivalsvision" onClick={this.navReset}>Get A Vision</NavLink>
          <NavLink className="nav-sub-elem" to="/festivalsteam" onClick={this.navReset}>Build A Team</NavLink>
          <NavLink className="nav-sub-elem" to="/festivalsguide" onClick={this.navReset}>Festival Guide</NavLink>
          </div> : ""}
        </section>
        <section className="sub-nav-conferences">
          {this.state.conferences ? <div className="sub-nav">
          <NavLink className="nav-sub-elem" to="/conferencesvision" onClick={this.navReset}>Get A Vision</NavLink>
          <NavLink className="nav-sub-elem" to="/conferencesteam" onClick={this.navReset}>Build A Team</NavLink>
          </div> : ""}
        </section>
        <section className="sub-nav-concerts">
          {this.state.concerts ? <div className="sub-nav">
          <NavLink className="nav-sub-elem" to="/concertssteps" onClick={this.navReset}>Steps To Take</NavLink>
          <NavLink className="nav-sub-elem" to="/concertsdayof" onClick={this.navReset}>What You Need To Know Day Of</NavLink>
          </div> : ""}
        </section>
        <section className="sub-nav-artistDevelopment">
          {this.state.artistDevelopment ? <div className="sub-nav">
          <NavLink className="nav-sub-elem" to="/artistdevelopmentvision" onClick={this.navReset}>Get A Vision</NavLink>
          <NavLink className="nav-sub-elem" to="/artistdevelopmentepk" onClick={this.navReset}>Online Promotion/EPK</NavLink>
          <NavLink className="nav-sub-elem" to="/artistdevelopmentlive" onClick={this.navReset}>Solidify Your Live Performance</NavLink>
          </div> : ""}
        </section>
        <section className="sub-nav-yourFirstTour">
          {this.state.yourFirstTour ? <div className="sub-nav">
          <NavLink className="nav-sub-elem" to="/firsttourvocabulary" onClick={this.navReset}>Industry Vocabulary</NavLink>
          <NavLink className="nav-sub-elem" to="/firsttourmerch" onClick={this.navReset}>Merchandise</NavLink>
          <NavLink className="nav-sub-elem" to="/firsttoursmarttour" onClick={this.navReset}>Touring Smart</NavLink>
          </div> : ""}
        </section>
      </div>
    )
  }
}


export default Nav
