import React from 'react'
import '../App.css';
import pic1 from '../images/ArtistDevelopmentEpk1.jpg'

const ArtistDevelopmentEpk = () => {
  return (
    <div className="concerts-content main">
      <h1>Online Promotion and Creating an EPK</h1>
      <div>
        <p>Here is a list of some things that will be needed for proper promotion of your band.  These are standard for the industry and needed for marketing in the digital and analog worlds.  Some of these you have already completed,  but others you have not.  This is made in a checklist form to help you gain more solid footing as you set the foundation for the next steps.</p>
        <span className="span-for-side-pics">
        <ul>
        <li>Professional Photos</li>
        <li>Short Bio of the band and each band member</li>
        <li>Expanded Bio of the band and each band member</li>
        <li>Logo</li>
        <li>Website</li>
        <li>Social Media Pages:  Facebook, Instagram, Twitter (These need to be active and showing you have stuff going on - feed these onto your website.  Social media is social pointing people to your website for more information.)</li>
        <li>Press releases to send to local and other media.  These can be prewritten and then tweaked for whatever the need is.  2 forms: one for show announcements and one about the band in general - can include your short bio</li>
        <li>Contact email</li>
        <li>Other contact information</li>
        <li>Manager or other representative of the band to connect with and how</li>
        <li>Booking agent/booking information</li>
        <li>Business plan (written outline of what you want to achieve and the road map to get there)</li>
        <li>Content for social media (photos, videos, memes, and so on...let them get to know you a bit.)</li>
        <li>Merchandise design (you need to appear to have longevity planned)</li>
        <li>Merchandise products</li>
        <li>Live performance video (show crowd responses)</li>
        <li>Other videos to show what you do best</li>
        <li>Recorded music (something to use to promote what you do to venues and festivals)</li>
        <li>You need something to promote...shows, tours, appearances, events, other activities you are participating in.</li>
        </ul>
        <img className="general-images" src={pic1} />
        </span>
      </div>
    </div>
  )
}

export default ArtistDevelopmentEpk;
