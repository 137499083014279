import React from 'react'
import '../index.css'
import pic1 from '../images/ConcertsSteps1.jpg'

  const ConcertsSteps = () => {
    return (
      <div className="concerts-content main">
        <h1>Steps to Take</h1>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/5O4aYFSt0JE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <p>
          Hosting a concert is so exciting!!  There are a lot of moving parts, but once they are all set, you should be able to sit back and relax as you enjoy the show!  If they aren’t set, you will be putting out a lot of fires.
        </p>
        <p>
          A few things you will need to know are the people you will be working with and their roles.   Here is a brief vocabulary list for you. Make note that often, one person could easily be wearing more than one hat, so be patient and ask questions instead of becoming upset or stopping communication. There may be other people involved as well.
        </p>
        <span className="span-for-side-pics">
        <div>
        <p>
          <strong>Booking Agent - </strong>The person who books the shows.  They act as the go between for the venue and the band to communicate through.  They get the gigs and sign the contracts. They get paid up front out of the deposit (if there is one.)
        </p>
        <p>
          <strong>Promoter - </strong>The person who produces the show by marketing, taking over communication with the venue, directs traffic leading up to the show from the event side, and makes sure all of the needs for the show are met.  This person is responsible for fulfillment of the contract on the venue side. This person also is responsible for ticketing and money distribution for all parties.
        </p>
        <p>
          <strong>Venue - </strong>Provides the location for the show.  They have general practices and final say as to what can and can not happen on stage safety wise and if needed, morally (sex, language, etc)  They generally provide the AV crew and equipment as well as stage hands as needed.
        </p>
        <p>
          <strong>Ticketing Agent - </strong>This person/group is responsible for all ticket sales, promo codes, and money associated with ticket sales.  They pay the agent unless otherwise stated.
        </p>
        <p>
          <strong>Artist/Band Manager - </strong>This person is responsible for the band: their whereabouts, making it to load in on time, communicating with the promoter, receiving band monies, and all other needs the band members may have.
        </p>
        <p>
          <strong>BOH - </strong>Back Of House - crew, stage manager, and private artist spaces; sometimes includes monitor and stage microphones for sound and effects.
        </p>
        <p>
          <strong>FOH - </strong>Front Of House - responsible for what the stage looks and sounds like to the crowd; audio visual.
        </p>
        <p>
          <strong>Artist - </strong>The performers on stage.
        </p>
        <p>
          <strong>Stage Manager - </strong>Person in BOH keeping things organized and moving on time.
        </p>
        <p>
          <strong>Merch Manager - </strong>Person in FOH in charge of selling band merchandise.  They also are responsible for set up, tear down, inventory, and tracking/reporting funds from merchandise sales.
        </p>
        <p>
          <strong>Publicist - </strong>Works for the band or promoter to do press releases and set up press interviews.
        </p>
        </div>
        <img className="general-images" src={pic1} />
        </span>
        <p>
          These people are also involved with festivals, conferences, and other events.  Again, one person may wear several hats covering multiple areas in this process.
        </p>
        <p>
          <strong>Host a Concert Pre-Show Steps: </strong>In no particular order as there are a lot of variables to dictate the order
          <ul>
            <li>Book the bands</li>
            <li>Book the venue</li>
            <li>Book band hospitality in conjunction with the venue</li>
            <li>Prepare stage plot and get it along with any special notes to the venue AV team to ensure they have the equipment they need.</li>
            <li>Communicate show information to all parties.  This includes load in time, show time, guest list, vip list, meet and greet expectations, show order, backline and set changes, set lengths, load out procedure, sound checks, hospitality, and any other details the team needs to know.</li>
            <li>Meet the promoter and make a marketing plan, how can the band help?</li>
            <li>Determine the ticket prices and work with the venue/promoter for ticket sales</li>
            <li>Talk about the show and invite people</li>
          </ul>
        </p>
      </div>
    )
  }

export default ConcertsSteps
