import React from 'react'
import pic1 from '../images/FestivalPlanning1.JPG'
import pic2 from '../images/FestivalPlanning2.jpg'

const FestivalsGuide = () => {
  return (
    <div className="festivals-content main">
      <h1>FESTIVAL PLANNING</h1>

        <em>*Please note that this is not EVERYTHING, but instead, a general guide*</em>
        <h2>INTRODUCTION</h2>
        <span className="span-for-side-pics">
          <div>
            <p>This is a general planning guide for music festivals. It is difficult to incorporate all details into
            one guide as each festival is a little different and takes on its own personality pending the
            purpose, vision, organizers, budget, and other support it has. So take this as a starting point.
            Use the information there and then add your own to it so you have it from year to year. Add
            your specific purpose, vision, and goals. Add the names of the team members who are taking
            responsibility for each area. Add the specific names and contact information for production
            and for the festival location. Add add add add add add. This is a living breathing document
            that is here to help you. Other forms can be created to go with it such as your festival
            contract, a band information list (band name, contact info, genre, cost, etc), volunteer list
            (who did what, contact info for next year to start a base), vendors (contact info, paid/cost,
            etc), and so much more. This is your festival…make it what it is intended to be. Keeping track
            of all of this info will help each successive year improve in efficiency and growth. (It gets easier
            each time you do it.)</p>
          </div>
          <img className="general-images" src={pic1}/>
        </span>
        <h2>PURPOSE</h2>
        <p>The first part of planning a festival is to define your purpose. Why are you doing this? What is
        the go to statement that describes this festival? After the purpose or vision is in place, you
        need to consider how you choose to achieve this and what the end picture looks like. If there
        is no end picture, what are you working toward and how do you know what to do to work to
        achieve that goal? Starting small is great…but what is the end? Are you wanting the festival to
        grow? Is this an outreach project or entertainment? How do you know you have arrived? Do
        you plan to charge an admission fee? If so why and how much? This is something you will
        have to discuss and decide as a committee.</p>
        <h2>SPONSORSHIP/NON-PROFIT STATUS</h2>
        <p>If this is not a one-time event, you will want to have a business to work under or to apply for a
        not for profit status for your festival/group/organization so you are an official business. If you
        are planning to solicit donations for the festival, a 501(c)3 status is recommended so donors
        can write the donation off on their taxes. This makes fundraising much easier as well as
        adding a few other benefits you can learn about as you go through the process. This process
        generally takes some time, so get started on it right away if this is the path you are wanting to
        take.</p>
        <h2>SELECTING A LOCATION</h2>

        <p>The location you use for the festival can make it easy or difficult to achieve the goal you
        choose to accomplish. You need a location that will allow room to grow and to achieve the
        goals you have set in place. Be sure there is space for stages with ample power, viewing
        stages, vendors with power available, a way to provide well lit parking, backstage area, lighted
        backstage parking with space for busses and vans with trailers as needed, green room type
        area for the artists (with catering area), and multiple entrances. Camping is also a nice bonus
        if the festival lasts for more than one day, electric and tent camping are both important as well
        as a location for bands sleeping in their vehicles. Another factor to consider in the location is
        noise. Anytime you have bands playing and people present, there will be noise. Be sure to
        check into what you have to do as far as permits and ordinances in the area are concerned to
        have a successful event. You will also want to check with the city to ensure you have their
        support if it is near town.</p>
        <h2>LOGISTICS OF THE LOCATION</h2>
        <p>Stages can be set up in a variety of ways. The number of stages you desire mixed with the
        space you have available can make a big difference in how the festival operates. There are
        two basic ways stages can be set up. One is to have two stages side by side. As one band
        plays, the other is setting up. They switch at a designated time and the music doesn’t ever
        stop. It is continuous throughout the entire fest. Another way to do it is to have the stages
        facing away from each other and in different locations on the grounds with a staggered start
        time even though there may be some overlap. This format allows each stage to cater to a
        particular type of audience. For Scheduling of bands, see that section later on. For a smaller
        festival, you can also use one stage and have all of the bands play on that stage. There are
        some strategies for this listed later under scheduling.</p>
        <p>When mapping out and considering a location for a festival, it is important to consider the
        traffic flow and restroom locations as well as what areas should be off limits to the general
        public and how to achieve that. The orange snow fence is a great and commonly known
        method for achieving control of traffic flow by using it to block off areas not accessible to the
        general public. It is important to ensure safety of the bands and their equipment and to keep
        people where they are safe and under control. Check for safety hazards as well as places
        people may congregate. Be sure to include enough restrooms for all of the people at the
        festival. Portable potty companies know the ratios needed for the number of people you
        expect at your event. This is a bigger issue for outside festivals than inside, but be sure to
        consider them. It would be good to assign specific groups to be responsible for keeping the
        restrooms clean and picked up of trash. This makes a better impression and more comfortable
        experience for festival goers and thus entices people to return the next year.
        Another often overlooked area is trash cans. Be sure there are a sufficient number of them
        available in all locations. Place them at building entrances and exits as well as a few dispersed

        throughout the stage areas. Have a trash can behind each stage as well. The more convenient
        it is to toss something the less likely it will end up on the ground. Having roving trash
        collectors is also a great idea. The thought of putting it on the ground is not even an option
        and it gives the volunteers a chance to interact with the public to chat and make a personal
        connection with the festival causing a return visit next time. It would be good to have
        someone assigned to check the trash cans regularly to ensure they aren’t overflowing and
        defeating the purpose of having them where they are.</p>
        <p>Keeping a count of how many people are at the festival is important for a lot of reasons.
        Wristbands are an excellent way to do this as you just have to count the ones not handed out
        at the end of the day to know how many people were there as long as everyone gets one.
        Another advantage is that you can use varying colors to represent different things such as
        camping, backstage/band member, general admission, VIP, and so on. Just keep track of each
        one separately and figure out the numbers after the fest is over as some may overlap
        throughout. This count will be handy when booking bands and fundraising.</p>
        <h2>TICKETING</h2>
        <p>Set up ticketing information in an account such as Eventbrite for easier tracking, sales, and
        display of options. You should still have attendees get tickets for free events and festivals so
        you have the data you need for analyzing the event and fundraising data as needed. You will
        be able to tell who comes to the festival, how many attended, their ages, demographics, other
        like events they attend, and a whole lot more! By asking bands to report the merch they sold,
        you can cross analyze that with the people attending and get a solid view of the people who
        are attending. This will make targeted marketing easier and cost effective. It will also make
        branding more clear as you target the population you want to see at your festival.</p>
        <h2>STAGES/PRODUCTION</h2>
        <p>You then need to determine what size of a festival is feasible for the grounds (remember that
        it takes some time to grow into a large festival so this needs to be evaluated every year) as
        well as how many stages you would like to have for the size of crowd you have. This will vary
        by the location and average attendance of the festival itself if you are not in the first year of
        the festival. You don’t want people spread too thinly or it will look like no one is there even if
        there are thousands. When the number of stages and locations are determined, the next step
        is to find and confirm the equipment needed for it such as stage rental/building, lights, sound
        equipment and people to operate it and so on. Be sure you talk to the production company
        regarding the size of bands you are planning to bring in so the stages can be the appropriate
        size for the event. You don’t want the stage to be too small for the band and their
        equipment…nor too huge.</p>
        <h2>INSURANCE</h2>

        <p>After all of this has been determined and confirmed, you will need to purchase insurance for
        the event. Do some research and check into a variety of locations to compare prices and
        coverage. Choose the coverage that best fits your event. Just a side note: most insurance
        companies charge extra to cover moshing at metal/hardcore festivals due to the increased
        chance of injury and expenses. You need to ask specifically about this if you plan to have
        heavier music at the festival. Also, ask what can be done to cover your side of it so people
        know what is and is not covered…ex. Posting the rules/no moshing/ mosh at your own risk
        etc…</p>
        <h2>LIVE PERFORMANCE LICENSE</h2>
        <p>Be sure you contact BMI, SESAC, and ASCAP to get live performance licensing. This way
        royalties can be paid to the musicians and songwriters. This will also cover background music
        played between sets. This is important to both the artist and the festival as it shows integrity.
        If it is a free event, there will only be a minimal charge for the background music played
        between sets.</p>
        <h2>FUNDRAISING</h2>
        <p>Fundraising can be done using numerous methods. It would be wise to have a team dedicated
        entirely to fundraising unless your church or business plans to pay all of the expenses itself.
        Using a combination of these methods is recommended. One way to raise funds is to do
        fundraising events. By doing this, you create not only some funds for the festival but it also
        creates exposure to the communities in which you raise funds, important for a later section of
        this document. These activities can include a booth at events such as RAGBRAI, parades,
        carnivals, other shows, car washes, restaurant special fundraising nights, special dinners,
        garage sales, auctions, mid-year shows, and other creative ideas. Remember these have two-
        fold purpose. They also take time to organize and require volunteer commitment. Successful
        events occur each year and grow as the festival grows. They become habit and then turn into
        tradition. They also take some time.</p>
        <p>Another fundraising method is simply to ask. Start with local businesses and people in the
        community and reach out from there. Selling ads with benefits such as being featured in a
        power point near the stage, name on a banner, name on the program, name on the social
        media, on the website, on the t-shirts sold/volunteer shirts, and more can be incentives for
        sponsorship. You could even go as far as to have businesses sponsor a stage and put their
        name on the stage with set dollar amounts for each level of sponsorship. This makes it a
        business transaction. Be sure to follow through and to send thank you cards after the event
        for the sponsors. If local and regional sponsors aren’t enough, look nationally. That will take
        some time and most national sponsors require you to start at least one year ahead of the date
        you are looking at for your event, so get started with these right away!!!! You can give them

        the same options as you do the other sponsors…but can also add more perks for more dollars.
        When requesting funds, you can ask for funds for specific areas of the festival such as family
        activities, particular stages, to pay the bands, for hospitality, for volunteers, etc. or you can ask
        for a general fund to use as the festival determines. Just remember that as soon as money is
        donated, all eyes are upon you to see what you may do with it. To ensure the money isn’t
        wasted or put to a different use, so keep very specific records in your fundraising and
        expenses.</p>
        <p>Every event attracts the attention of vendors. You will want to have spaces for food vendors
        and a set fee or percentage of sales for them to adhere to as their fee to participate in the
        event. The vendors that are not food vendors will need spaces as well. These vendors should
        pay a straight fee unless they are selling items, then it is a percentage of the sales or a set fee.
        There are pros and cons to each method of payment to participate. You will have to decide
        which works best for you and your team.</p>
        <p>Don’t forget the option to have donation buckets out at the festival as well as perhaps having
        one out all year around in a local location for people to donate as they are able. You can take
        up offerings at the event if you choose to. Just be sure the emcees are aware of the plan so
        they can help talk it up as they speak.</p>
        <p>Another option could be to charge a gate fee. As a committee, you will have to determine if
        this fits your purpose or not before doing it. Even if it is a free festival, you could consider
        selling VIP seating and honors as a bonus fundraising effort.</p>
        <h2>BUDGET</h2>
        <p>Be sure that you take some time and create a budget. Sometimes things cost a lot more than
        you planned and you need to be aware of what money is being spent where doing what. It
        would be wise to have this in writing someplace with a specific person in charge of keeping
        track of it bookkeeping wise as well as to help you stay focused on your purpose and vision.
        You need a place to start in your planning, so keep track from year to year and create a new
        budget according to what you experienced the year prior and by watching trends in the
        industry.</p>
        <h2>FAMILY ENTERTAINMENT</h2>
        <span className="span-for-side-pics">
          <div>
            <p>It is important that if your event is targeted to families or young people who have young
            families, that you provide other activities for the kids to do. This is because kids need a way to
            get some energy out throughout the day. Many festivals use the inflatable games for this. If
            this is a choice you make, be sure the inflatables come with their own liability insurance so
            that is not an issue you have to deal with. There are other options for this part of the festival
            such as a carnival game type area, water games, pedal tractor pulls, tricycle races, and

            more….just a special place kids can hang out and have some fun. If this is more than your
            festival committee can handle, you may consider asking some local youth groups to provide
            activities for the kids to do or even Boy Scouts, Girl Scouts, 4-H, other ministries in the area or
            other service organizations. Often these groups are wanting to participate for special projects,
            exposure to the community, fundraisers for them, or just to do something to give a hand.
            Make this a place where anyone in the community can get involved if they choose to. Even a
            business may be interested in sponsoring a spot in the family entertainment area. The key is
            that each group that participates is responsible for the supplies and manning of every station
            they offer to provide. They do it completely so the festival only has to oversee it.</p>
            <p>This should be a safe place for the kids to be both emotionally and physically. Because of this,
            security is important. It will attract whole families instead of just a few of the people and it
            will then draw more to the music throughout the day.</p>
          </div>
          <img className="general-images" src={pic2}/>
        </span>
        <h2>FOOD VENDORS</h2>
        <p>These have been mentioned previously in other sections. They are an important part of your
        event. Each state is different about permission to sell food at events like festivals. Know your
        state’s law and if an inspection certificate is needed to vend (like in IOWA), then having a copy
        of that certificate on file would be of a great benefit to you in case there are any questions.
        You will want to be sure there is ample power for each vendor as well as be aware of their
        needs for space. Another consideration is to ensure there is a large variety of food at a
        reasonable price range. Mix traditional “fair food” (corn dogs, funnel cakes, pizza, cotton
        candy) with solid “fill your belly” food (pit BBQ, pork chop on a stick, burgers, Asian food of
        some sort, Mexican food) and then add some fun stuff (ice cream, coffee shop, smoothies,
        monkey tails, chocolate dipped bacon). The variety will attract a large crowd and keep them
        at the fest instead of them leaving to get food and not coming back. You will want to be sure
        each menu is different and that water is readily available at most of the vending locations.
        Also, double check the prices to ensure they are affordable for people who will be at the event
        all day for the duration of the event. Maybe even have a range of prices so people can have a
        choice.</p>
        <p>As far as a fee, it was mentioned earlier that you can do it as a set fee or a percentage of the
        food sold goes back to the festival. It is a matter of how you choose to keep track of it. A set
        fee is easier…they pay it or they don’t and it can be done ahead of time so you have the funds
        to work with. A percentage is paid at the end of the event before they leave the grounds.
        Specific set up and tear down times are recommended unless other arrangements are made
        ahead of time.</p>
        <h2>OTHER VENDORS</h2>
        <p>Other vendors such as church groups, businesses, organizations, and new projects will ask to

        set up booths at your event. It is wise to have a set fee for these vendors as well as a general
        location for them to set up pending what the booth is about. One option is a varied industries
        type of an area and another is to have an area near each stage pending the focus of the event.
        A set fee is the best option for these groups as well as having a specific set up and tear down
        time so they are committed to the event. Be sure you have a description of what they plan to
        do at the booth and know that their missions match that of the festival’s purpose to keep
        unity in the event.</p>
        <h2>HOSPITALITY FOR BANDS/GUESTS/ VOLUNTEERS</h2>
        <p>You will need to feed the bands and volunteers who give you a hand to tell them thank you.
        Figure out which meals you will need to serve and make a plan to serve the people needed at
        each meal time. Often, bands and volunteers are given meal tickets or are in some way
        marked to ensure they have permission to eat the meal. (See more on that later.) Pending
        your resources, at least one meal is provided to each. These can be catered or homemade.
        There are a few things to consider for each time frame. 1. You should have multiple options or
        a variation to create options for each meal to accommodate for general food allergies and
        special diets. 2. You should have a vegetarian option available for each meal as many are
        trying to eat healthy while on the road. 3. Meal times should accommodate the artists on the
        stage(s). Most artists prefer to eat after they perform so for the bands slated to play during a
        meal time, they need to have food available to them after they play. This also includes the
        bands loading on and off of the stage during set serving times. It is frustrating to jump off the
        stage hungry and then to go to eat and nothing be there. Sack lunches are better than
        nothing. Snacks in the mid afternoon and later in the evening are also highly recommended.
        Some meal suggestions are tacos, soups, salads, casseroles with a vegetarian option, breakfast
        food, fresh fruits and veggies, beef burgers, and so on. There should be enough food to cover
        the number of people eating.</p>
        <p>It is important to offer showers and clean restrooms in the hospitality area as well as air
        conditioning (in the summer) if possible so the bands have a safe cool place to go when they
        need it. Bands and volunteers can eat together. They are all there for the same purpose. The
        bands go on stage and give their all….usually sweating out their all….and leaving some there
        but still requiring a shower afterward as well as some water, Gatorade, Powerade, etc . It is
        important to provide some relief from the heat for them.</p>
        <h2>STAGE MANAGERS/MERCH MANAGERS</h2>
        <p>These folks help keep things moving. The stage manager runs the back of the house to make
        sure bands are in sync and stay on time with what is going on backstage. The Merch Managers
        are front of the house. The make sure the merch tables are set up and available as well as
        helping direct traffic for bands as the day progresses and the crowd changes. They also watch

        the gates/ entrances to be sure only the people who are supposed to be there are actually
        there. Merch Managers direct traffic in the front end and answer questions of guests and
        bands; Stage Managers direct traffic in the back end. Some stages are small enough that the
        same person can do both jobs but ideally, they are two different people.</p>
        <h2>VOLUNTEERS</h2>
        <p>Volunteers can make or break your festival. It is important to have enough volunteers to
        complete all of the tasks at hand. In addition, you need to be sure that all of your volunteers
        are aware of the position and times they have agreed to and are trained in how to perform the
        job they are there to do. It is also important to try to match the volunteers with jobs they
        know or enjoy for the most effective personalities in the positions to be filled. This will help
        with retaining volunteers for future festivals.</p>
        <p>You will want to be sure your volunteers are appreciated and designated. Often this is done
        by giving volunteers special t-shirts to wear or lanyards or something of that sort to designate
        them from the crowd and to give them a gift to say thanks. There are some other things that
        can be done above and beyond the standard, so be creative in saying thank you. Please be
        sure your volunteers have sufficient water and restroom breaks during their shifts. If they
        work for 4 hours or more, then a meal is a standard thank you as well.
        Some festivals prefer to recruit all of their volunteers through a single channel to ensure they
        are all recognized and others give that responsibility to the department heads. This is
        something you will have to decide as a committee. How to you want to recruit, assign, track
        and maintain volunteers?</p>
        <h2>SECURITY</h2>
        <p>Security is a position to be taken seriously volunteer or paid. This position can be boring but
        you must be prepared for absolutely anything! This includes alcohol, illegal drug use,
        weapons, abuse, and so forth. Even the most pure fests have issues to deal with. Security
        must be on the look-out at all times and be completely aware of the area around them. You
        need people to check backstage access, to ask questions, to be looking for safety issues, to
        watch for hazards, to monitor moshing at all times and so much more. Be sure your team
        understands what they are watching for and why. Also be sure they know how to deal with it
        when they see it. Security must be on patrol in areas of the grounds that may not be as highly
        populated throughout the day. Places such as a stage that is finished early, parking lots,
        camping areas, and so on. If there is an after-hours party, it needs to be secured as well. The
        main gate needs security as does every other part of the festival grounds. Security should work
        with the group doing logistics to identify potential hazards and problem areas to be monitored
        on a regular basis. This is one area that you can’t have too many volunteers for.</p>
        <h2>INFORMATION BOOTH</h2>

        <p>This needs to be located in an easy to find location on the festival grounds. This is a central
        communication point. There should be a way to communicate with all of the core team as
        well as security so there is not any question about what is happening. It is kind of a central
        headquarters. It is also a place for volunteers to check in and get their assignments as well as
        other items needed for their job and a great place for will call tickets/VIP info/meet and greet
        passes and so on. It is also a good place to locate programs for visitors and other information
        that needs a central location.</p>
        <h2>CENTRAL OFFICE</h2>
        <p>This location should be limited access and should hold office equipment and files necessary for
        the festival including, but not limited to a computer, printer and band/vendor agreements.</p>
        <h2>SCHEDULING</h2>
        <p>Before you start booking bands, you need to figure out how you want to schedule them so you
        can have an idea for a time that they will play when you book them. There are a variety of
        methods you can use to set up a schedule and if none of the methods suggested here work,
        just find your own method as this is a guide to get you started.</p>
        <ul>
        <li>One Stage Event: Determine a goal end time and work backwards. After you determine
        the end time, allow 60 minutes for the headliner to play. The 30 minutes prior to that
        would be for them to set up. Each set before that should be allowed 45 min- 1 hour to
        set up, sound check, play, and tear down. Most bands have a 30-35 minute set so 1
        hour to do all of this works well…45 minutes if you are pressed for time or have several
        bands you want to put into the day. 15 minutes is usually sufficient time for a set
        change. If you need it to go faster, 10 minute change overs, then supply a drum set to
        be used as well as possibly cabs and heads. Drums are the part that take the longest to
        switch. Another way to expedite the change-over is to put the drum risers on wheels.
        Have the drummer of the next set set up as the previous plays and then wheel them out
        and set the breaks while the other one switches over during the next set. This takes
        some coordination, but is quite slick when used well. Many venues will layer bands and
        just take sets away as they are played but that isn’t always possible at a festival. These
        will work for most stages.</li>
        <li>Side by Side Two Stage Event: Create a schedule so each band has 30-40 minutes to
        play plus a 5 minute sound check one right after another with no breaks in the middle.
        As one band plays, the other band sets up and takes over at the end of the previous
        band’s set rotating between the two stages with no overlap. ****Don’t forget to see if
        the Headliners need any extra time to set up before confirming your scheduled times.
        **** After you make the master list of who plays when, you may want to make a
        separate list for each stage so it is just a tad less confusing to look at.</li>

        <li>Three or More Stages: These stages are separated so they are ok if they overlap just a
        bit. Generally, when this is done, each stage caters to a particular group of people until
        the last bands of the night. Ensure they are facing away from one another so the sound
        doesn’t overlap. When creating the schedule, follow the steps for the one stage event
        above for each stage. You may want to be creative in the timing though like have one
        stage start on the half our and another on the hour and give each band an hour to set
        up, sound check, play, and clean up. If you have a lot of bands that people want to see,
        maybe have a smaller band play on one stage while a larger band plays on another, then
        switch so the bigger band follows the smaller band and vice versa…alternating the
        stages throughout the day. With four stages, try staggering every 15 minutes a stage
        changes. 60 minutes is a good time frame for each band to do their thing…including the
        headliners. More than that, people get bored of them. Less than that, leaves them
        wanting more. Some of your headliners may do better with a longer show, but not
        more than 90 minutes. Just some thoughts.</li>
        </ul>
        <h2>BOOKING BANDS</h2>
        <ul>
        <li>Determine your budget. How much do you have to spend on this event? Most bands
        need at minimum gas money, meals, and on some occasions, lodging as well as their
        normal pay. Is this feasible? There are a lot of variables in this area pending the band
        you determine to be the best fit for your event. The normal fee is $100 to $200,000
        pending the type and size of artist, their schedules, and transportation. This is why your
        budget is important.</li>
        <li>You will want to book your headliners FIRST.</li>
        <li>Check the tour schedule(s) for the band(s) you are interested in. Are they currently
        booked for that date? If they are, what is the next band you are interested in? Upon
        finding the band you are interested in to be free, contact the booking person for the
        band. (You may want to use a promoter to help with the booking process if you do not
        understand the vocabulary used.)</li>
        <li>After the band(s) is/are confirmed and contracts have been signed (some bands don’t
        require contracts, so don’t panic if you don’t get one), It is time to work on the
        requirements in the riders (production, meals, etc.) IT IS OK IF YOU CAN’T MEET EVERY
        NEED IN THE RIDER. Just be sure you communicate what you are and are not able to
        provide to the band/management so they know what to expect. If you don’t agree,
        don’t sign it. Be sure you confirm all of the bands you speak with in writing as soon as
        the agreement is made. This resolves any question as to if the band will be arriving at
        the festival or not…or at least if they are expected to arrive. A confirmation email is
        sufficient, but there needs to be more communication beyond that as well…..see BAND
        COMMUNICATION.</li>
        <li>In addition to the contract you may or may not have with the band, you can establish

        a festival contract. This will spell out what the festival is able to provide and
        arrangements made. This should also include the check in/out process as well as the
        load in/out process and set information such as times and set up and so on to avoid
        question as to what the expectations are. You can include a no moshing clause as well if
        you choose to. Some make an additional form for this. See BAND COMMUNICATION</li>
        <li>Paying the bands can be complicated. Some want deposits paid ahead of time.
        Awesome. Pay them. Most don’t mind waiting until they get to the festival to get pad.
        Have that ready so that when they check into the festival, they get their pay. Using a
        check or gift card are great ways to pay as they are easy to track. Cash is great as well
        but difficult to track or prove. No matter the preferred method of payment, please be
        sure that if it is something made out to a specific person such as a check, that you ask
        ahead of time who to write the check to or you may be rewriting multiple checks to
        various individuals. Many can’t cash a check if written to the band itself. This is a
        question you can put on a band confirmation form or on the festival contract to make
        communication easier and less questionable.</li>
        <li>Choosing bands to book can be a difficult task as there is a balance you have to find and
        often a budget you have to meet. You need to book a variety of bands in genres and
        sizes to pull in a good crowd. Book some locals to play in the morning to get people
        pumped up for the day. Book regional bands for the afternoons for people just waking
        up and getting off work…make it worth their time to be there. Follow this with the
        national bands as co-headliners to draw the evening crowds. Festival booking is always
        a challenge as you can book and set it in perfect order, have contracts signed and ready
        and checks written……and still have cancellations and changes in the line ups. Things
        just happen to make it change. Be realistic and don’t release specific times until close to
        the festival date. Tell the bands about a week before unless it is specified in the
        contract, and tell the general public the day before or the day of the festival. This way
        you can produce the most accurate possible schedule online. Programs need to be
        printed sooner, but you can make it accurate as of print date. Same with
        posters…..posters don’t really need more than a starting time for the first band on them
        for each day. If they want more info, refer them to a website or fb page for the latest
        updates. Be sure the music styles/ genres and lyrics of the bands you book match the
        purpose and vision of the festival. Unity in the purpose is imperative to success!</li>
        <li>Have some back up bands in mind in case of cancellations.</li>
        </ul>
        <h2>BAND COMMUNICATION</h2>
        <p>It is important to be in continual communication with the bands you have booked for the
        festival. First, you should have had an application for them to complete. This should include
        what you need to know to determine if they are a good fit for your festival. Things that can be
        included are simply their social media links, some song samples, how to contact them, their

        genre, their target audience as a band, a photo to use for advertising, and other questions you
        may have. As a Christian festival, questions can include: Do all of your members believe in
        Jesus? Are you born again? Is your band an active ministry? How do you minister to the
        people you reach with the music you play? Why do you do what you do? And so on….Also,
        ask for an mp3 copy of a song that can be used for later advertising. This comes in handy for
        promo videos, a compilation cd, and more. The rest of the permissions and information can
        be on a follow up confirmation email. You want your application to be about the band and the
        music they play primarily…or about their faith if it is a factor. The confirmation message
        should include permissions, festival info, and other miscellaneous information they need to
        know. Request they send a return to acknowledge they have seen the confirmation. It may be
        handy later. Then, monthly communication and updates should be sent to the bands. If you
        are holding an advertising competition or something that includes all of the bands, that will
        help with communication. The week of the festival, contact all of your bands with an email to
        confirm the final details such as gate entrance, number of artists in the group, meal plans,
        where to check in, performance time, load in time and process, and any other important
        info….stage manager phone numbers are nice as well. This should be followed up with phone
        calls to see if there are any other questions. Sometimes that sparks a memory that the band
        has of another show they have to play that day or other events that may be happening in the
        lives of the bands at the moment. Be prepared to flex and adjust as needed to fill in the gaps
        that may occur at the last moment.</p>
        <h2>BAND CHECK IN</h2>
        <p>Band check in should be located near the band entrance area and should have someone
        available the entire time of the festival…even at night. This entrance should be separate from
        the general entrance as the bands often have vans with trailers or busses to maneuver around
        backstage. There needs to be space to accommodate for this.</p>
        <p>At the check in area, the band should receive everything they need for the entire festival. This
        includes their pay (have them double check the name on the check before leaving the area),
        meal tickets (or explain how the system works), directions to their stage, load in/out
        instructions, any forms that need signed by the band (no moshing rules, contracts), a reminder
        of festival purpose, vision, and the rules, important phone numbers to know, camping tags,
        water, t-shirts, water bottles, anything else you need to get to all of the band members…they
        get from the check in area. This may take a little time and organization, but it makes it a one
        stop spot and less confusing for the rest of the festival (especially if there are multiple stages.)
        A spreadsheet with the band name and contact phone number as well as a place to check off
        each item needed is a handy way to keep track of all of it.</p>
        <p>Bands should also be encouraged to checkin/out of the festival area when they come/go as
        well. This way, if there is a question of their location, at least you know if they are on the

        grounds or not.</p>
        <p>The band vehicle area should be a closed area where others aren’t able to enter to help with
        securing the vehicles and equipment in them. Each band should have instruction as to where
        to go to set up merchandise (usually at the stage they play at unless a centralized location is
        available for all bands to use.)</p>
        <h2>TECHNOLOGICAL/MEDIA TEAM</h2>
        <p>This person needs to be able to operate social media, the website, and create the power
        points for the festival. They need to stay current on the event as well as on the bands and
        other activities happening to ensure that information gets to the public quickly and in a
        manner they can understand. Multiple generations require a variety of forms of information.
        Creating video clips and fliers to appeal to different groups would be great or creating a video
        that encompasses all of the audiences would word as well. Audiences to consider: teens,
        young singles, young families with little children, middle aged adults with older kids, middle
        aged singles, elderly people. These groups all require differing marketing strategies to gain
        interest. There are also church and non-church groups. A lot of this can be addressed in
        presentation. These are listed to create an awareness in the presenter….informational or for
        fundraising….to be aware of. This person needs to have a complete understanding of what the
        festival is about and who it is targeting. Internet and media have tons of influence over the
        audience, so it is imperative that the correct message is sent and not a conflicting one. The
        lack of unity can really hurt a festival. The purpose and vision have to come through every
        part of it!!!!</p>
        <h2>ADVERTISING &amp; PROMOTIONS</h2>
        <p>Getting word out about the festival is one of the most difficult things to do, especially the first
        few years. No matter what you do, there will always people who tell you they didn’t know
        about it…so hard to hear locally when you have worked your tail off for it. Just plan to hear it
        (and put them on the list of people to contact when raising funds for next year ☺)
        There are a variety of ways to advertise. They can’t possibly be all covered in this overview.
        The more effective means we have found have been radio (usually around $500), television
        talk shows, fliers, social media, bands themselves advertising, website with search key words
        noted, word of mouth, personal invitation(#1!), fundraisers in the community, getting more
        people involved (they know about it when they are committed to be there and advertise
        without thinking about it!), signs on the roads around the festival, signs in the surrounding
        communities, contests, free stuff to pick up at the festival, something to make them feel
        special, business card size flyers people can put into their wallets, handing out reminders at
        concerts with similar music, parades (hand out flyers, have a live band perform…any time of
        the year!) Keep the promotion going on all year around and really push it the 4-6 weeks prior

        to the festival happening. The more times people hear about it and from it, the more the
        excitement can keep rolling and thus creating interest. Keep talking about it….all of the time!
        There tends to be a lull after a festival is over when people need time to recover and get ready
        to go again. It is important that this time does not last six months. You have to keep the
        momentum going and pull on the memories from the previous year to keep it your audience
        engaged in what you are doing. It also helps keep things building during the initial planning
        time when there isn’t really anything going on in public but behind the scenes. It could be as
        small as social media asking who should we bring next year? What would you like to see at
        ??? What additions would you enjoy? Include pictures when you are talking to people so they
        remember and don’t just skim over it.</p>
        <p>Make your presence known in the area. Start speaking at churches, community groups,
        regular business meetings, and so on to introduce yourself and what you are doing. Why are
        you doing this and how can they help or participate? What are your advertising rates? How
        does the festival help the businesses? You have tons of competition for the same dollars.
        What makes what you are doing unique or special? How can they relate? People tend to give
        with their emotions. Key words are how to find the money in your hands….leading, teaching,
        entertaining youth…teaching Jesus…unity…revival, young people….key words to what you are
        doing. Even if you aren’t fundraising in particular, you can still use the opportunity to educate
        others as to what the purpose is and you can plant seeds for future contact for fundraising.
        (You should always be fundraising &amp; networking as well as looking for new ideas or new ways
        to do things.) When someone asks how they can be more involved or comes to you with an
        idea, ask them if they would like to head that up. It gets them involved with their ideas…and
        their ideas are accepted meaning they are accepted. Try not to turn down any speaking /PR
        opportunity. If you can’t be there, can you send another team member? Can you send a
        video and some materials? Create a video apologizing for not being able to be there and then
        tell about the festival. There are so many ways to do this!!! Get creative!!!!</p>
        <h2>PARKING</h2>
        <p>Parking is always an issue to consider at festivals. Where do you put all of those cars?? You
        need ample parking space for the attendees. VIP special parking for those who have given the
        extra effort to help the festival either financially or by putting in their time. Parking flow
        should be clearly marked with people out and about to ensure the routes are followed for
        optimal flow and use of space. Shuttles would be nice to have if possible for getting people
        from their vehicles to the entrance gate and back again. Security should be readily available
        and roving the parking area as well to ensure the safety of the people at the festival. Lighting
        needs to be sufficient. If electrical lights aren’t available, perhaps flood lights with generators
        would be better. Keep a close eye on this for the safety of the attendees. If parking passes are
        used, someone needs to be out checking passes are accurate. Band parking should be in a

        different area same with camping parking.</p>
        <h2>CAMPING</h2>
        <p>If your festival is longer than one day, camping is a great option as hotels often max out
        quickly for the event between bands and attendees. Tent camping is common but there
        should be spaces for campers as well. Electrical sites need to be available for both tent and
        camper attendees due to some medical conditions requiring electrical flow. Determine a rate
        for the festival. One rate no matter how many days you camp or a daily rate and stick to it.
        Use wristbands to show who is camping and who is not so security knows who has permission
        to be in the camping area. This will also help with counts when larger groups camp together.
        If people are sleeping in their vehicles, what are the rules for that? What about the fire pit
        rules? Are there restrooms/ showers available? (One gets stinky when dancing in the heat!) If
        not, what are the options? Things that are good to know for campers. Are alcohol, smoking,
        etc permitted? Pets? Just some general thoughts. Is there a dump station available? Clean
        water hook up for campers? Be sure these are available to attendees in some form.
        Where/how do campers register to camp? Are there designated male/female/group areas?
        What are the rules for those? Security needs to canvas the camping area frequently to watch
        for illegal activities and unregistered vehicles/ people who don’t need to be on the grounds. Is
        there a shelter if it storms? Do you have a plan to get campers out if it is too muddy or wet to
        get them out in the case of rain?</p>
        <h2>AFTER HOURS PARTY</h2>
        <p>If you plan to do a multiple day event, you may consider doing an after hour party to keep the
        momentum going. This can be done with a surprise band or a DJ.. Often done by 1:30 or 2 am
        so the crowd can get a few hours of sleep before a big next day at the fest…or do it on the last
        night so they all sleep well on the way home ☺ Choose a location and add them to the roster.
        These sometimes take a while to catch on, but once they do, you are on the move!</p>
    </div>
  )
}

export default FestivalsGuide;
