import React from 'react';
import { Route, Switch, withRouter } from "react-router-dom";
import Nav from './components/Nav'
import Footer from './components/Footer'
import Home from './components/Home'
import FestivalsVision from './components/FestivalsVision'
import FestivalsGuide from './components/FestivalsGuide'
import FestivalsTeam from './components/FestivalsTeam'
import ConferencesVision from './components/ConferencesVision'
import ConferencesFoundation from './components/ConferencesFoundation'
import ConferencesTeam from './components/ConferencesTeam'
import ConcertsSteps from './components/ConcertsSteps'
import ConcertsDay from './components/ConcertsDay'
import ArtistDevelopmentVision from './components/ArtistDevelopmentVision'
import ArtistDevelopmentEpk from './components/ArtistDevelopmentEpk'
import ArtistDevelopmentLive from './components/ArtistDevelopmentLive'
import FirstTourVocabulary from './components/FirstTourVocabulary'
import FirstTourMerch from './components/FirstTourMerch'
import SmartTouring from './components/SmartTouring'
import NoMoreSadSongs from './components/NoMoreSadSongs'
import logo from './logo.svg';
import './App.css';
import melindaHomePage from './images/MelindaHomePage.jpg'
import logoRainbow from './images/LogoRainbow.png'

function App() {
  return (
    <div className="">
      <Nav />
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/festivalsvision' component={FestivalsVision} />
        <Route exact path='/festivalsguide' component={FestivalsGuide} />
        <Route exact path='/festivalsteam' component={FestivalsTeam} />
        <Route exact path='/conferencesvision' component={ConferencesVision} />
        <Route exact path='/conferencesfoundation' component={ConferencesFoundation} />
        <Route exact path='/conferencesteam' component={ConferencesTeam} />
        <Route exact path='/concertssteps' component={ConcertsSteps} />
        <Route exact path='/concertsdayof' component={ConcertsDay} />
        <Route exact path='/artistdevelopmentvision' component={ArtistDevelopmentVision} />
        <Route exact path='/artistdevelopmentepk' component={ArtistDevelopmentEpk} />
        <Route exact path='/artistdevelopmentlive' component={ArtistDevelopmentLive} />
        <Route exact path='/firsttourvocabulary' component={FirstTourVocabulary} />
        <Route exact path='/firsttourmerch' component={FirstTourMerch} />
        <Route exact path='/firsttoursmarttour' component={SmartTouring} />
        <Route exact path='/nomoresadsongs' component={NoMoreSadSongs} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
