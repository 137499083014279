import React from 'react'
import '../App.css';
import pic1 from '../images/YourFirstTourVocab.jpg'

const FirstTourVocabulary = () => {
  return (
    <div className="concerts-content main">
    <iframe width="560" height="315" src="https://www.youtube.com/embed/tqG5BssfW9I" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <h1>INDUSTRY VOCABULARY</h1>
    <p>SHOW: can be interchanged with concert; any time you play on stage in front of people.
    Concerts tend to be larger shows.</p>
    <p>CONCERT: can be interchanged with show; any time you play on stage in front of people.
    Concerts are larger shows.</p>
    <p>FESTIVAL: A show with more than 6 bands in a setting. (Lol...or any that use porta-potties). A
    festival varies in length and in location (indoor vs outdoor) and the number of stages varies.
    Know that festivals are more than a glorified show. They are a celebration and people come for
    a variety of reasons. The booking and lineup order varies according to the local area response
    to the artists. There is an art to it. NO MATTER WHAT, you DO NOT COMPLAIN about the
    position you get in the lineup as there are many who would be happy just to have your spot!!
    You are replaceable as festivals often receive hundreds or thousands of applications every year
    and can only fill so many spots. Be patient and dedicated. You will get your chance.</p>
    <p>EPK: Electronic Press Kit - This is what you send to booking agents, promoters, festivals, and
    more to tell people who you are and what you are doing. It is your chance to stand out in the
    crowd and to shine bright. Your EPK should consist of a SHORT bio, all of your social media
    links, your website, 1 or 2 video links (at least 1 live performance), an MP3 or two, and a
    professional photo. This is so people who have to go further up to book the show have
    something to share about you quickly and easily. When booking shows, this is an important
    part of the email you will be sending out to people. It needs to be a true depiction of who you are
    and what you do. When including mp3's for festivals, be sure to include one that has a very
    short intro and more with the vocals and chorus as most people won't listen to more than 7-10
    seconds of a song before making a decision about the band/artist. Please be aware of this!!
    If you are doing your own booking, be sure to format the email and EPK so they are easy to
    use. Subject line should read; band, Date at Venue/festival (The Persuaded July 10 at Red
    Rocks). The Body should start with a greeting and the business part (what you want) followed
    by what you are offering....the band EPK(S) and then a nice thank you for your time.</p>
    <p>LOAD IN: The time you load equipment into the venue. (Or the act of loading it into the venue).
    It is important you are on time for this and you do it as efficiently as possible. 15-20 minutes
    early is ideal (gives you time to wake up and hit the restroom before approaching the venue.)
    Follow the directions of the person representing the venue as they know what works best for
    their location.</p>
    <p>LOAD OUT: The act of returning your equipment to the vehicle you are traveling in. Load out as
    soon after your set as you can. This will make more space for others back stage and it will save
    you time later when you want to be talking to the other artists and people who came to see you.
    Be sure you have a designated person assigned to scope out the venue for any forgotten items
    you may be leaving behind. Be sure to clean up after yourselves placing trash and empty
    bottles (or partially used ones) in the appropriate containers as well as leaving things tidy and in
    good order. It will help you be invited back later.</p>
    <p>BACKLINE: equipment the venue (or tour) provides for you to use. RESPECT IT - NO
    MATTER THE QUALITY! It is not yours, so care for it even better than your own.</p>
    <p>SET: The time you have on stage from set up to tear down.</p>

    <p>SET LIST: The list of songs you plan to perform. Please have enough of them ready that all of
    your stage folks can see them as well as one for the sound person so they know where you are.
    If you have special notes for a song, write it on the list for the sound person so they can work
    with you.</p>
    <p>GEAR: Your instruments and all things attached to them for use during a show. This includes
    XLR cables, microphones and stands, amps, guitars, and more.....all equipment you need for
    success.....or all equipment not back lined.</p>
    <p>MONITORS: There are traditional monitors called floor monitors (where the sound comes to
    you on the stage from a speaker facing you) and in ear monitors (used more commonly now,
    they are monitors that are custom made and placed directly into the artist's ears like an earbud
    would be). You need to know which you use and if the venue provides them. In Ear monitors
    are most often custom to the artist and therefore the artist brings with them. Be sure you have
    batteries and if you use them, direct boxes. You don't want equipment to be the reason you
    can't perform.</p>
    <p>PRODUCTION: Sound and lights. The people who run these need to be treated with respect.
    Learn the commonly used hand signals to make things go more quickly.</p>
    <p>SOUND CHECK: The time after you set up and before you start your set (the performance).
    You need to be sure everything is in working order, that you can hear in the monitors you are
    using and that you have the proper range of sound you need from your equipment. This should
    only take a few minutes for each instrument. Try playing part of a song as a full band and then
    hit it. BE NICE TO THE PERSON RUNNING SOUND!!! They literally have the ability to make
    the show amazing or stinky and they work hard too.</p>
    <p>MERCH/MERCHANDISE: The stuff you sell at your table. Keep it clean, uncluttered and
    organized. This is often your gas and food money, so be sure you keep it ready to sell to
    people. Have an attractive display and give people a reason to hang out there....such as band
    members to talk to, cool stuff to purchase, video games, sign up lists, and so on. Get creative.
    Be sure to set this up as early as possible to ensure your display is what you want and not
    missing parts. You will also want to be ready well before the doors open so you are able to do
    things like use the restroom (if desirable) and get water or a space prior to meeting and talking
    with people.</p>
    <p>BACK STAGE/GREEN ROOM: Back stage is the space you may have to get ready for the
    performance. Green room is generally a space back stage where you can change clothes and
    use the restroom and sometimes there is food. There are a few unwritten rules to know:</p>
    <span className="span-for-side-pics">
    <ol>
    <li>Everyone backstage is working and thus if not working, you have no reason to be there.</li>
    <li>BE NICE TO EVERYONE.....even if you want to shoot them. :-)</li>
    <li>Keep your space tidy and leave it cleaner than when you arrived.</li>
    <li>If you have to share a green room, respect the other band.</li>
    <li>Keep all conversation at the venue positive on all occasions. It is too easy to get a negative
    attitude and down everything you encounter.</li>
    <li>Try to remember names of all you meet. It means a lot down the road....fans, promoters,
    bands and others.</li>
    <li>Say hello and goodbye at the venue.</li>
    <li>DO NOT FANGIRL!!!</li>

    <li>SUPPORT OTHER ACTS AND DO NOT LEAVE EARLY unless it is life and death! It is
    important you support. You are the leaders. Find something positive to say to and about
    them.</li>
    <li>The biggest thing with back stage is that you shouldn't need to be there much. Meeting
    fans, mingling, supporting bands, hanging out and chatting should take up most of your
    time.</li>
    </ol>
    <img className="general-images" src={pic1} />
    </span>
    <p>GUARANTEE: This is the money you were guaranteed to earn for playing the show you are at.
    It is a set amount to receive no matter who or how many show up. Merchandise sales are
    bonus money not to be taken lightly.</p>
    <p>DOOR SPLIT: Usually this is a percentage agreed upon while booking. Sometimes the venues
    will include merchandise sales in the door splits as well so be sure you know exactly what the
    agreement is. 60/40 is a common split....60 percent to you and 40 percent to the venue. The
    amount you make is pending the number of people who come through the door and pay.</p>
    <p>BUY ON: This term applies to tours you pay to be on. Make sure the amount makes sense for
    your budget as well as for the worth of the tour. What do you get out of it?</p>
    <p>PRE-SALES: A term used to describe tickets you were required to sell to get on a certain show.
    Most commonly, this is used for local bands but sometimes the task falls to touring bands as
    well. If pre sales are required, make sure you know how many and for how much as well as if
    this will effect the position in which you play.</p>
    <p>VENUE, BAR, CLUB, CHURCH, GIG: These are all types of places you may play shows.</p>
    <p>OPENERS: Usually locals; gives them exposure to play with the touring bands and public
    exposure. Generally these are not paid or are only paid minimally. Do NOT complain about
    your spot or do an encore in this position. It will take away from the show and will take precious
    time from other bands on the ticket.</p>
    <p>SUPPORT: Bands on the tour or show package that are not headliners or direct support. The
    job of support bands is to hype the crowd. They are to get the energy moving and the
    enthusiasm up for the ones yet to come. Great support bands become headliners.</p>
    <p>DIRECT SUPPORT: This is the band or artist playing directly before the headliners. This band
    is up and coming into the next level of play and has their own draw of people for the show.
    They are generally paid and may or may not be with the tour pending how many bands are
    touring at the time.</p>
    <p>HEADLINERS; In the past, it was the band that plays last. Currently, it is the band that has the
    best draw nationwide. It is still generally the last band of the night but sometimes, a local band
    makes a better closer due to keeping a crowd there. IT IS UP TO THE PROMOTERS TO
    DETERMINE SHOW ORDER!!! They want the best show for you as that is the best show for
    them and their wallets.</p>
    <p>*Side Note: NO MATTER WHERE IN THE SHOW YOU PLAY, be sure to support all of the
    bands on the ticket! It is easy to tear another band apart, but try instead to find something
    positive to say about them and to them. All artists need to be encouraged in their art and who
    better than ones who do the same thing!?! People in the crowd look up to the ones on stage.
    You need to be a positive example of staying for all of the bands so that the scene stays alive!</p>

    <p>MANAGER: (personal manager) The person who oversees your band and helps you gain
    direction and support. Often this person is knowledgeable in the biz and can help you along.
    Generally paid 15-20% of your gross per show.</p>
    <p>MANAGER: (business manager) takes care of the money and bookkeeping aspects, often a
    CPA. Takes care of royalties, taxes, receipts, and more. Generally charges 5% of gross income
    or a set fee or hourly rate.</p>
    <p>ATTORNEY: deals with the major power borers you encounter. They should be heavily
    involved in negotiations when you sign contracts with publishers, labels, managers, and agents.
    Generally they charge 5% of any deals they negotiate or can work for an hourly rate or flat fee.</p>
    <p>PUBLICIST: The person who works on your public image and who helps you determine what,
    when and how to release information to the public. They also obtain media coverage for clients
    in print, tv, and electronic media. They do all kinds of stuff including sending press kits to music
    writers, communicating with managers and record labels and hiring hair and make up teams for
    tv and photo shoots. Publicists get paid an average of $1500-$5000 per month and begin work
    several months before major releases and announcements.</p>
    <p>BOOKING AGENT: The one who books your shows for you. This person is integral to the
    growth of your art. They are the connecting point with bigger tours and venues. Be picky.
    Booking agents generally charge 10-15% of gross income beyond merchandise sales.</p>
    <p>PROMOTER: The person who is paying for you to be on stage. This person publicizes the
    event and then oversees it to be sure things run smoothly.</p>
    <p>VENUE OWNER; The person who owns the venue. Often they are the point of contact, but not
    always.</p>
    <p>TOUR MANAGER/TM: Keeps band members accountable, determines routing and bus call
    times, communicates between the artists and the venue/promoter, and all other jobs not yet
    assigned to anyone. A larger tour tends to have a TM for each band and then one for the
    overall tour.</p>
    <p>*Side Note: These jobs are listed separately but the roles often overlap from one place to
    another. Just be aware of the person you are talking to and what their responsibilities are. This
    is important, especially when you have questions and when you want to get paid.</p>
    </div>
  )
}

export default FirstTourVocabulary;
